export const generateRandomVariant = () => {
  const randomNum = Math.floor(Math.random() * 5)
  switch (randomNum) {
    case 0:
      return 'primary'
    case 1:
      return 'warning'
    case 2:
      return 'success'
    case 3:
      return 'info'
    case 4:
      return 'danger'

    default:
      return 'primary'
  }
}

export const generateIndexVariant = index => {
  switch ((index + 1) % 4) {
    case 0:
      return 'success'
    case 1:
      return 'primary'
    case 2:
      return 'warning'
    case 3:
      return 'info'

    default:
      return 'secondary'
  }
}

export const resolveUserRoleVariant = role => {
  if (role === 'client') return 'primary'
  if (role === 'moderator') return 'success'
  if (role === 'editor') return 'info'
  if (role === 'admin') return 'warning'
  return 'primary'
}

export const resolveUserRoleIcon = role => {
  if (role === 'client') return 'UserIcon'
  if (role === 'moderator') return 'SettingsIcon'
  if (role === 'editor') return 'Edit2Icon'
  if (role === 'admin') return 'ServerIcon'
  return 'UserIcon'
}

export const verifEmailVariant = verifEmail => {
  if (parseInt(verifEmail, 10) === 1) return 'success'
  return 'danger'
}

export const verifEmailIcon = verifEmail => {
  if (parseInt(verifEmail, 10) === 1) return 'CheckIcon'
  return 'SlashIcon'
}

export const resolveUserMotivationStatusVarian = status => {
  if (status === 'create') return 'info'
  if (status === 'accept') return 'success'
  if (status === 'denial') return 'danger'
  if (status === 'forbidden') return 'secondary'
  if (status === 'rewiew') return 'warning'
  if (status === 'revision') return 'warning'

  return 'primary'
}

export const resolvePayoutStatusVarian = status => {
  if (status === 'create') return 'info'
  if (status === 'accept') return 'success'
  if (status === 'denial') return 'danger'
  if (status === 'rewiew') return 'warning'
  if (status === 'paid') return 'success'

  return 'primary'
}

export const resolveUserMotivationStatus = status => {
  if (status === 'create') return 'Создана заявка'
  if (status === 'accept') return 'Заявка принята'
  if (status === 'denial') return 'Отказ'
  if (status === 'forbidden') return 'Заблокирован'
  return status
}

export const resolveUserPayoutMotivationStatus = status => {
  if (status === 'create') return 'Создана заявка'
  if (status === 'review') return 'Заявка на рассмотрении'
  if (status === 'denial') return 'Отказ'
  if (status === 'accept') return 'Одобрено'
  if (status === 'paid') return 'Выплачено'

  return status
}

export const resolveUserMotivationChanged = changed => {
  if (parseInt(changed, 10) === 1) return 'EyeOffIcon'
  return 'EyeIcon'
}

export const pluralBonusType = amount => {
  const indexes = [2, 0, 1, 1, 1, 2]
  const variant = ['бонус', 'бонуса', 'бонусов']
  const mod100 = amount % 100
  const mod10 = amount % 10
  const index = (mod100 > 4 && mod100 < 20) ? 2 : indexes[(mod10 < 5) ? mod10 : 5]

  return `${amount} ${variant[index]}`
}

export const clearRReceiveDate = receiveDate => {
  if (typeof receiveDate === 'object' && Array.isArray(receiveDate)) {
    return `${receiveDate[0].substring(0, 4)}${receiveDate[0].substring(5, 7)}${receiveDate[0].substring(8, 10)}T${receiveDate[1].substring(0, 2)}${receiveDate[1].substring(3, 5)}`
  }

  if (receiveDate.indexOf('T') !== -1) {
    const arData = receiveDate.split('T')
    return `${arData[0].substring(0, 4)}${arData[0].substring(5, 7)}${arData[0].substring(8, 10)}T${arData[1].substring(0, 2)}${arData[1].substring(3, 5)}`
  }

  const arData = receiveDate.split(' ')
  return `${arData[0].substring(0, 4)}${arData[0].substring(5, 7)}${arData[0].substring(8, 10)}T${arData[1].substring(0, 2)}${arData[1].substring(3, 5)}`
}

export const clearSumm = totalSum => {
  if (totalSum.indexOf('.9999') !== -1) {
    const cop = String(parseInt(totalSum.substring(totalSum.indexOf('.9999') - 2, totalSum.indexOf('.9999')), 10) + 1)
    return `${totalSum.substring(0, totalSum.indexOf('.9999') - 2)}.${cop.substring(cop.length - 2, cop.length)}`
  }
  if (totalSum.indexOf('.0000') !== -1) {
    return `${totalSum.substring(0, totalSum.indexOf('.0000') - 2)}.${totalSum.substring(totalSum.indexOf('.0000') - 2, totalSum.indexOf('.0000'))}`
  }
  return `${totalSum.substring(0, totalSum.length - 2)}.${totalSum.substring(totalSum.length - 2, totalSum.length)}`
}
