<template>
  <b-media
    vertical-align="center"
    class="text-left"
  >
    <template #aside>
      <b-avatar
        size="32"
        :src="user.file ? user.file.path : null"
        :text="avatarText(`${ user.firstName } ${ user.lastName || '' }`)"
        :variant="`light-${generateRandomVariant()}`"
        :to="{ name: 'apps-users-view', params: { id: user.id } }"
      />
    </template>
    <b-link
      :to="{ name: 'apps-users-view', params: { id: user.id } }"
      class="font-weight-bold d-block text-nowrap"
    >
      {{ user.firstName }} {{ user.lastName || '' }}
    </b-link>
    <small class="text-muted">{{ user.phone }}</small>
  </b-media>
</template>

<script>
import { BMedia, BAvatar, BLink } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { generateRandomVariant } from '@/utils/UserFilter'

export default {
  components: {
    BMedia,
    BAvatar,
    BLink,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      avatarText,
      generateRandomVariant,
    }
  },
}
</script>
