<template>
  <b-row>
    <b-col cols="12">
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row class="align-items-center justify-content-between">
            <b-col
              cols="6"
            >
              <label>Показывать по</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>записей</label>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
                />
              </div>

            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refTicketQueue"
          class="position-relative text-center"
          :items="fetchItems"
          responsive
          :fields="Columns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Совпадающих записей не найдено"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                :disabled="!ability.can('manage', 'all')"
                @click="deleteTicketQueue(data.item.id, refetchData)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #cell(createdUser)="data">
            <avatar-table-call :user="data.value" />
          </template>

          <template #cell(operationType)="data">
            <span class="align-text-top">{{ operationType(data.value) }}</span>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="`light-${ resolveTicketQueueStatusVarian(data.value) }`">
              {{ resolveUserTicketQueueStatus(data.value) }}
            </b-badge>
          </template>

          <template #cell(orderId)="data">
            <b-link
              :to="{ name: 'sale-admin-view', params: { id: data.value } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.value }}
            </b-link>
          </template>

          <template #cell(createdAt)="data">
            {{ formatDate(data.value) }}
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из {{ dataMeta.of }} элементов</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BPagination,
  BLink,
  BBadge,
  BFormInput,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import AvatarTableCall from '@/components/user/AvatarTableCall.vue'
import { formatDate } from '@/libs/helper'
import { deleteTicketQueue, getElementList } from '@/application/ticketVerificationQueueService'
import ability from '@/libs/acl/ability'

import {
  resolveTicketQueueStatusVarian,
  resolveUserTicketQueueStatus,
  operationType,
} from '@/utils/SystemFilter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
    BLink,
    BBadge,
    BFormInput,
    BDropdown,
    BDropdownItem,
    vSelect,
    AvatarTableCall,
  },

  setup() {
    const {
      refTicketQueue,
      dataMeta,
      fetchItems,
      Columns,
      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,

    } = getElementList()

    return {
      refTicketQueue,
      Columns,
      dataMeta,
      fetchItems,

      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,

      formatDate,

      resolveTicketQueueStatusVarian,
      resolveUserTicketQueueStatus,
      operationType,
      deleteTicketQueue,
      ability,
    }
  },
}
</script>
