export const resolveTicketQueueStatusVarian = status => {
  if (status === 'create') return 'info'
  if (status === 'received') return 'success'
  return 'info'
}

export const resolveUserTicketQueueStatus = status => {
  if (status === 'create') return 'Созданый'
  if (status === 'received') return 'Полученный'
  return status
}

export const operationType = typeId => {
  if (parseInt(typeId, 10) === 1) return 'Приход'
  return typeId
}
